.react-spinner {
    width: 15px;
    height: 15px;
    border: 1px solid;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: react-infinite-spinner linear 1.1s infinite;
    -moz-animation: react-infinite-spinner linear 1.1s infinite;
    -o-animation: react-infinite-spinner linear 1.1s infinite;
    animation: react-infinite-spinner linear 1.1s infinite;
}

@keyframes react-infinite-spinner {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes react-infinite-spinner {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes react-infinite-spinner {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes react-infinite-spinner {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}