
.quicklookdiv {
    position: relative;
    max-width: 900px;
    color: white;
    text-align: inherit;
    margin: 150px auto;
    padding: 15px;
    letter-spacing: normal;
    line-height: 1.625;
}

.quicklookdiv-p {
    margin-left: 3em;
}

.quicklookdiv-h1 {
    padding: 0.2em;
}

.cv-href {
    text-decoration: none;
    line-height: 4em;
    color: white;
    padding: 1.1rem 1.1rem 1.1rem 1.1rem;
    font-weight: 300;
    border: 1px solid white;
    text-align: center;
    font-size: 1.0rem;
    box-sizing: border-box;
    border-radius: 1em;
    margin-right: 1em;
    cursor: pointer;
    transition: all 0.15s;
}

.cv-href:hover {
    background-color: #747983;
}

.dots-div {
    min-width: 220px;
    display: inline-block;
    padding: 15px;
    margin-left: 0.6em;
    margin-top: 0.6em;
    border: 1px solid #5a4747;
    border-radius: 1em;
}

.balls-p {

}

.balls-div {
    max-width: 200px;
    padding: 16px;
}

.category-h1 {
    max-width: 500px;
    font-size: 1.85em;
    cursor: pointer;
}

@media (max-width: 500px) {
    .category-h1 {
        font-size: 1.3em;
    }

    .dots-div {
        min-width: 120px;
    }
}

.plus-sign {

    height: 1em;
    width: 1em;

    display: inline-block;
    color: white;
}

.empty-dot {
    height: 18px;
    width: 18px;
    background-color: #828282;
    border-radius: 50%;
    display: inline-block;
    margin-left: 1px;

}

.dot {
    margin-left: 1px;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
}