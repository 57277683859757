.page-wrap {
    position: relative;

    margin: 200px auto;
    text-align: inherit;
    padding: 20px;
    opacity: 0.85;
    color: white;
}
.yth2 {
    font-size: 18px;
    margin-top: 3em;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
.thanks-submit {
    font-size: 14px;
    display: inline;
    white-space: break-spaces;
}

.yth1 {
    font-size: 25px;
}
.ytp {
    font-size: 18px;
    margin-top: 1.5em;
    color: white;
    text-decoration: none;
    pointer-events: none;
}
#contact-form {
    max-width: 500px;
    background-color: #111214;
    border-radius: 1em;
    padding: 1em;
}

.btn-primary:hover {
    cursor: pointer;
    background-color: #d8d8d8;
}
#contact-form .form-group {
    padding: 0.5em;
    color: black !important;
}
#contact-form .form-control {
    width: 100%;
    resize: none;
    color: black !important;
    border: 1px solid darkgrey !important;
    margin-top: 1em;
    padding: 0.5em;
    border-radius: 1em;
}
#contact-form .submit-div {
    width: 100%;
    color: black !important;
    margin-left: 10px;
    border-radius: 1em;

}
#contact-form .btn-primary {
    padding: 0.4em;
    font-size: 20px;
    border: 1px solid darkgrey !important;
    color: black !important;
    background-color: #d8d8d8 !important;
    font-weight: 500;
    letter-spacing: 0.2em;
    border-radius: 1em;
    max-width: 20rem;
    margin: 10px auto;

    text-align: center;
}

#contact-form .label-text {
    color: white;
    padding: 0.2em;
    text-align: left;
}

.contact-page {
    color: #fff;
    font-size: 21px;
    letter-spacing: normal;
    line-height: 1.625;
    margin: 150px auto;
    max-width: 500px;
    opacity: .85;
    padding: 15px;
    position: relative;
    text-align: inherit;
    z-index: 0!important;
}