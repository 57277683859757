.Header {
    position: fixed;
    top: 0; /* Stick it to the top */
    max-height: 100px;
    width: 100vw;
    z-index: 1;
    display: grid;
    grid-template-areas: "logo nav";
    grid-gap: 0;

    /* Cosmetics */
    background-color: #111214;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    grid-template-columns: 170px 800px;
}

.logoNav {
    grid-area: logo;
    height: 100px;

}

.Logo {
    grid-area: logo;

    height: 70px;
}

.linkToHome {
    width: 70px;
}

.Nav {

    grid-area: nav;
    float: right;
    z-index: 3;
    line-height: 35px;
    grid-template-columns: repeat(4, auto);

}

.homebck {
}

.Nav a {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;

}

.Nav a:hover {
    color: grey;
}

.Nav button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    transition: 70ms;
}

.Nav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.menuButton {
    max-width: 80px;
    padding: 0.2em;
}

.IconItem {
    display: none;
    grid-area: iconitem;
    margin: 0 20px 0 0;
    padding: 10px;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
}

.IconItem:active {
    transform: scale(1.2);
}

.iLogo {
    color: white;
}

@media (max-width: 700px) {
    .Header {
        background-color: #111214;
        grid-template-areas: "logo iconitem" "nav nav";
        grid-template-columns: auto;
    }

    .liItems {
        width: 100% !important;
        background-color: #111214 !important;
        margin-top: 5px !important;
    }

    .Nav {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: none;
        grid-row-gap: 20px;
        overflow-x: hidden;
        direction: rtl;
        height: 300px;
        width: 100%;
        padding: 20px 0 30px;
        background-color: #111214;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .IconItem {
        display: inline;
    }
}

.liItems::-webkit-scrollbar {
    width: 13px;
}

.liItems::-webkit-scrollbar-track {
    background: #dbdbdb;
}

.liItems::-webkit-scrollbar-thumb {
    background: #282c34;
}

.liItems::-webkit-scrollbar-thumb:hover {
    background: #282c34;
}

.liItems {
    color: white;
    float: left;
    list-style: none;
    margin-top: 30px;
    min-height: 50px;
    z-index: 1;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 2em;
    font-size: 18px !important;
    cursor: pointer;
    text-align: left;
    vertical-align: top;
}

@media (max-width: 700px) {
    .Header {
        background-color: #111214;
        grid-template-areas: "logo iconitem" "nav nav";
        grid-template-columns: auto;
    }

    .liItems {
        width: 100% !important;
        background-color: #111214 !important;
        margin-top: 5px !important;
    }

    ::-webkit-scrollbar {
        width: 13px;
    }

    ::-webkit-scrollbar-track {
        background: #dbdbdb;
    }

    ::-webkit-scrollbar-thumb {
        background: #282c34;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #282c34;
    }

    .IconItem {
        display: inline;
    }
}

.NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
}

.NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
}

.NavAnimation-exit {
    opacity: 1;

}

.NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
}