.buttonYears {
    display: inline-block;
    padding: 1.2em 3.3em;
    border-radius: 1em;
    border: 0.16em solid #FFFFFF;
    margin: 0 1.3em 0.3em 1em;
    box-sizing: border-box;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;
    background-color: transparent;
}

.buttonYears:hover {
    color: #DDDDDD;
    border-color: #DDDDDD;
}

.buttonYears:active {
    color: #BBBBBB;
    border-color: #BBBBBB;
}

.popup-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    border-radius: 1em;
    top: 0;
    left: 0;
    z-index: 1;

}

.popup-container, .ReactModal__Content {
    background-color: transparent;
    margin-top: 1em;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: auto;
    -moz-transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    border-radius: 1em;
    max-width: 900px;

    max-height: 98vh;
    opacity: 1;
    -moz-animation: reveal .2s;
    -webkit-animation: reveal .2s;
    animation: reveal .2s;
}

.popup-textcontain {
    position: relative;

}

.download-image-container {
    position: relative;
}

.imageLarge {
    max-width: 50vw;
    max-height: 50vh;
    border-radius: 1em;
    z-index: 1;
}

@media (max-width: 1100px) {
    .imageLarge {
        max-width: 80vw;
    }
}

.overlay {
    background-color: #1e1e1e;
    opacity: .7;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

}

.close {
    position: absolute;
    right: 0.3em;
    top: 0;
    z-index: 2;
    width: 1em;
    height: 1.5em;
    color: white;
    margin-top: 10px;

    font-weight: bold;
    cursor: pointer;
}

.project-h2 {
    font-size: 1.4em;
}

.project-content {
    position: relative;
    max-width: 800px;
    margin: 150px auto;
    text-align: inherit;
    padding: 15px;
    letter-spacing: normal;
    line-height: 1.625;
    word-wrap: break-word;
    font-size: 21px;
    opacity: 0.85;
    color: white;

}

.project-image {
    max-width: 500px;
    padding: 1em;
    cursor: pointer;
    max-height: 300px;

}

.project-poster {
    max-height: 300px;
    max-width: 300px;
    padding: 1em;
    cursor: pointer;
}

.projectDiv {
    border-bottom: 1px solid white;
    padding: 0.3em;

}

.projectDescription {

}

@media (max-width: 500px) {
    .imageLarge {
        max-width: 80vw;
    }

    .project-image {
        max-width: 170px;
        padding: 0.2em;
        cursor: pointer;
        max-height: 300px;
    }

    .gitHubLinkButton {
        font-size: 0.85em;
        margin-top: 8px;
    }
}

@media all and (max-width: 30em) {
    .buttonYears {
        display: block;
        margin: 0.4em auto;
    }
}

.gitHubLinkButton {
    display: inline-block;
    padding: 0.46em 1.6em;
    margin-left: 0.5em;
    border: 0.1em solid #000000;
    cursor: pointer;
    max-width: 300px;
    border-radius: 1em;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    color: white;
    text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
    background-color: #120f0f;
    text-align: center;
    transition: all 0.15s;
}

.gitHubLinkButton:hover {
    background-color: #433d3d;
}

.skills-charts {
    height: 500px;
    width: 100%;
    padding: 1em;
}

.earth {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-size: cover;
    background: #fff repeat-x;
    box-shadow: inset 0 0 ;
}