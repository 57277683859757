.logoHome {
    width: 100%;
    margin-top: 4em;
}

.homeText {
    margin-block-start: 2em;
}

.homeHeader {
    text-align: center;
    font-size: 35px;
    color: rgba(207, 220, 252, 0.86);

    text-shadow: rgb(0, 0, 0) 2px 2px 2px;
}

.homeDiv {

    z-index: 0 !important;
    position: relative;
    max-width: 500px;
    margin: 150px auto;
    text-align: inherit;
    padding: 15px;
    letter-spacing: normal;
    line-height: 1.625;
    /*-moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;*/
    font-size: 21px;
    opacity: 0.85;
    color: white;

}

div {
    display: block;
}

.lang-flag {
    width: 70px;
    padding: 0.2em;
    cursor: pointer;
}

.btn {
    margin-top: 1em;
    position: relative;
    border: 1px solid white;
    border-radius: 1em;
    text-align: center;
    padding: 1.4rem 1.4rem 1.4rem 1.4rem;
    font-size: 1.1rem;
    text-decoration: none;
    color: white;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    user-select: none;
}

.btn:before, .btn:after {
    content: '';
    position: absolute;
    border-radius: 1em;
    transition: inherit;
    z-index: -1;
}

.btn:hover {
    background-color: #747983;
    transition-delay: .6s;
}

.btn:hover:before {
    background-color: #747983;

    transition-delay: 0s;
}

.btn:hover:after {
    background-color: #747983;

    transition-delay: .4s;
}

.from-right:before,
.from-right:after {
    top: 0;
    width: 0;
    height: 100%;
}

.from-right:before {
    left: 0;
    border-left: 0;
    border-right: 0;
}

.from-right:after {
    right: 0;
}

.from-right:hover:before,
.from-right:hover:after {
    width: 100%;
}
