.ul-footer {
    display: inline-grid;
    grid-auto-flow: row;
    grid-gap: 23px;
    justify-items: center;
    margin: auto;
}

@media (min-width: 500px) {
    .ul-footer {
        grid-auto-flow: column;

    }
}

@media (max-width: 700px) {
    .ul-footer {
        font-size: 9px;
        grid-gap: 4px;
    }
}

.a-footer {
    color: white;
text-decoration: none;
}

.li-footer:last-child {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}


/* below is just for demo styling */

.footer-content {
    display: inline-block;
    width: 100%;
    background-color: #111214;
    max-font-size: 14px;
    position: fixed;
    bottom: 0;
    text-align: center;

}


@keyframes wave-animation {
    0%,
    100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(20deg);
    }
    75% {
        transform: rotate(-15deg);
    }
}